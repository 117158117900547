import React from 'react';

// Components
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';

const ThankYouPage = () => {
  return (
    <Layout>
      <Seo title="Thank you page" />
      <Hero
        title="Thank you"
        description="Form has been submitted"
        ctas={[
          {
            title: 'Take me home',
            label: 'Take me home',
            ariaLabel: 'Navigate to home page',
            variant: 'Secondary',
            internalUrl: [
              {
                __typename: 'ContentfulHomePage',
              },
            ],
          },
        ]}
      />
    </Layout>
  );
};

export default ThankYouPage;
